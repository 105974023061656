import ProviderLogo from "components/ProviderLogo/ProviderLogo";
import React from "react";
import styled from "styled-components";

const OverlayContainer = styled.div`
     position: absolute;
     width: 100%;
     height: 100%;
     z-index: 999;
     opacity: 0;
     transition: all 1s cubic-bezier(0.76, 0, 0.24, 1);
     pointer-events: none;
     @media (min-width: 768px) {
          pointer-events: all;
          opacity: 1;
          background-color: ${(props) => props.theme.bg_processing};
     }
`;
export default function DesktopOverlay() {
     return (
          <OverlayContainer>
               <ProviderLogo />
          </OverlayContainer>
     );
}
