import classNames from "classnames";
import React, { useContext } from "react";
import styled from "styled-components";
import { ReactComponent as ArrowLeft } from "../../assets/svg/arrow_left.svg";
import { CheckoutContext } from "pages/CheckoutWrapper/CheckoutWrapper";
var cn = require("classnames");

const BackButtonContainer = styled.button`
     position: absolute;
     left: 32px;
     top: calc(32px + ${(props) => props.theme.logo.height.cart} / 2);
     transform: translateY(-50%);
     transition: opacity 0.6s cubic-bezier(0.76, 0, 0.24, 1);
     opacity: 1;
     border: none;
     background: transparent;
     margin: 0;
     padding: 0;

     &.zap-back-btn-hidden {
          opacity: 0;
     }

     svg * {
          fill: ${(props) => props.theme.input.arrowColor};
     }
`;

export default function BackButton() {
     const { currentStatus, setCurrentStatus, setIsEmailFormOpen } = useContext(CheckoutContext);
     const handleClick = () => {
          setCurrentStatus("email");
          setIsEmailFormOpen(true);
     };
     return (
          <BackButtonContainer
               onClick={handleClick}
               className={cn({ "zap-back-btn-hidden": ["email", "processing"].includes(currentStatus) })}
          >
               <ArrowLeft />
          </BackButtonContainer>
     );
}
