import axios from "axios";
import { API_URL } from "../config/config";

export const fetchAccountId = async (provider) => {
     const { data } = await axios.get(API_URL + "stripe/account/" + provider);
     return data.account_id;
};

export const fetchInvoice = async (invoice_id, provider) => {
     const { data } = await axios.get(API_URL + "stripe/" + provider + "/invoice/" + invoice_id);
     return data;
};

export const fetchInvoiceProducts = async (invoice_id, provider) => {
     const { data } = await axios.get(API_URL + "stripe/" + provider + "/invoice/" + invoice_id + "/products");
     return data;
};

export const fetchSubscription = async (subscription_id, provider) => {
     const { data } = await axios.get(API_URL + "stripe/" + provider + "/subscription/" + subscription_id);
     return data;
};

export const fetchCustomer = async (customer_id, provider) => {
     const { data } = await axios.get(API_URL + "stripe/" + provider + "/customer/" + customer_id);
     return data;
};

export const fetchPaymentMethod = async (payment_method_id, provider) => {
     const { data } = await axios.get(API_URL + "stripe/" + provider + "/paymentmethod/" + payment_method_id);
     return data;
};
export const fetchPaymentMethods = async (customer_id, provider) => {
     const { data } = await axios.get(API_URL + "stripe/" + provider + "/paymentMethods/" + customer_id);
     return data;
};

export const fetchShippingRates = async (provider) => {
     const { data } = await axios.get(API_URL + "stripe/" + provider + "/shipping_rates/");
     return data;
};

export const updateItemQuantity = async (item_id, quantity, provider) => {
     const { data } = await axios.post(API_URL + "stripe/" + provider + "/invoiceItem/" + item_id, {
          quantity: quantity,
     });
     return data;
};

export const payInvoice = async (
     invoice_id,
     payment_method_id,
     customer_id,
     billing_address,
     provider,
     use_shipping,
     shipping_rate
) => {
     const { data } = await axios.post(API_URL + "stripe/" + provider + "/invoice/pay/" + invoice_id, {
          payment_method: payment_method_id,
          customer: customer_id,
          billingAddress: billing_address,
          provider: provider,
          use_shipping: use_shipping,
          shipping_rate: shipping_rate,
     });
     return data;
};
