import Loader from "pages/Checkout/Loader";
import React from "react";
import styled, { useTheme } from "styled-components";
var cn = require("classnames");

const EnterButtonContainer = styled.div`
     position: ${(props) => (props.useAbsolutePos ? "absolute" : "relative")};
     bottom: 0;

     width: 100%;

     display: flex;
     justify-content: center;
     align-items: center;
     padding: 32px;
     transition: opacity 0.6s cubic-bezier(0.76, 0, 0.24, 1);

     &.disabled {
          opacity: 0.5;
          pointer-events: none;
     }
`;

const EnterButtonContent = styled.button`
     width: 100%;
     height: 100%;
     height: 80px;
     background: ${(props) => props.theme.input.bg};
     font-size: ${(props) => props.theme.input.fontSize};
     border-radius: ${(props) => props.theme.input.br};
     color: ${(props) => props.theme.input.fontColor};
     transform: scale(1);
     border: none;

     display: flex;
     justify-content: center;
     align-items: center;
     font-family: "Provider";

     transition: transform 0.05s cubic-bezier(0.76, 0, 0.24, 1);

     &:active {
          transform: scale(0.97);
     }
`;

function EnterButton({ onClick = undefined, enabled = true, isLoading = false, absolute = true }) {
     const theme = useTheme();
     return (
          <EnterButtonContainer onClick={onClick} className={cn({ disabled: !enabled })} useAbsolutePos={absolute}>
               <EnterButtonContent>
                    {!isLoading && <span>Enter</span>}
                    {isLoading && <Loader isVisible={true} isLoading={true} color={theme.input.fontColor} />}
               </EnterButtonContent>
          </EnterButtonContainer>
     );
}

export default EnterButton;
