export const BASE_URL = process.env.NODE_ENV == "production" ? "https://www.zapplugin.com/" : "http://localhost:3000/";

export const PROVIDER_SVG_FOLDER = "assets/svg/provider/";

export const LOGO_URL = "/images/logo.svg";
export const LOGO_SECONDARY_URL = "/images/logo-secondary.svg";
export const MAPBOX_TOKEN =
     "sk.eyJ1IjoiemFwcGx1Z2lucyIsImEiOiJjbDdxdW9ubGwwOTU5M3VwbG12MmRqeTB5In0.jwu95dzGc9Rj0y8be3TcnQ";

export const MAPBOX_PK =
     "pk.eyJ1IjoiemFwcGx1Z2lucyIsImEiOiJjbDc2bDc2am8wMDZxM3BwZjd2dWs2M2NtIn0.PWDo_5CxIx0QDeObiTR4iw";

export const API_URL = process.env.NODE_ENV == "development" ? "http://localhost:4242/api/" : "/api/";
