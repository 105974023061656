import React from "react";
import "./style.css";
import { useTheme } from "styled-components";

var classNames = require("classnames");

export default function Loader({ isVisible, isLoading, isReady = false, color = "#000" }) {
     var loaderClass = classNames({
          loader: true,
          ready: isReady,
          progress: isLoading,
          "zap-loader-hidden": !isVisible,
     });

     return (
          <div className={loaderClass}>
               <svg
                    xmlns="http://www.w3.org/2000/svg"
                    id="check"
                    width="30px"
                    height="30px"
                    strokeWidth="2.5"
                    version="1.1"
                    viewBox="0 0 45 45"
                    stroke={color}
               >
                    <circle id="circle" cx="22.5" cy="22.5" r="21.5" fill="transparent" stroke={color} />
                    <circle id="circle-background" cx="22.5" cy="22.5" r="21.5" fill="transparent" stroke={color} />
                    <polyline
                         id="tick"
                         xmlns="http://www.w3.org/2000/svg"
                         points="15 21.5607038 20.6296187 29 29 15"
                         stroke={color}
                         fill="transparent"
                    />
               </svg>
          </div>
     );
}
