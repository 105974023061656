import { CheckoutContext } from "pages/CheckoutWrapper/CheckoutWrapper";
import React from "react";
import { useContext } from "react";
import styled from "styled-components";
import { ReactComponent as ArrowDown } from "../../../assets/svg/arrow_down.svg";

const BillingPreviewContainer = styled.div`
     padding: 0 7px 32px 7px;
     display: flex;
     justify-content: space-between;
`;

const BillingAddressContainer = styled.div`
     flex-grow: 1;
     text-align: left;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     gap: 16px;
`;

const BillingAddressTitle = styled.div``;

const BillingAddress = styled.div`
     opacity: 0.5;
`;

const ArrowDownContainer = styled.div`
     display: flex;
     justify-content: center;
     align-items: center;

     svg * {
          fill: ${(props) => props.theme.input.arrowColor};
     }
`;

export default function BillingPreview({ onClick = undefined }) {
     const { billingAddress, providerConfig } = useContext(CheckoutContext);
     return (
          <BillingPreviewContainer>
               <BillingAddressContainer onClick={onClick}>
                    <BillingAddressTitle>{providerConfig.use_delivery ? "Delivery" : "Billing"}</BillingAddressTitle>
                    <BillingAddress>{!!billingAddress ? billingAddress.line1 : "Add"}</BillingAddress>
               </BillingAddressContainer>
               <ArrowDownContainer onClick={onClick}>
                    <ArrowDown />
               </ArrowDownContainer>
          </BillingPreviewContainer>
     );
}
