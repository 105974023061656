export const FormatPrice = (amount, currency = "usd") => {
     let formatted_price = new Intl.NumberFormat("en-IN", {
          style: "currency",
          currency: currency,
     }).format(amount / 100);
     return formatted_price;
};

export const FormatShippingRateDeliveryEstimate = (delivery_estimate) => {
     let output = "";
     if (!!delivery_estimate.minimum) {
          output += delivery_estimate.minimum.value + "-";
     }
     output += delivery_estimate.maximum.value + " ";
     output += delivery_estimate.maximum.unit + (delivery_estimate.maximum.value > 1 ? "s" : "");
     return output;
};

export const addressFeatureToAddressLine = (feature) => {
     try {
          const address_line1 = feature.properties.address_line1;
          return address_line1;
     } catch {
          return undefined;
     }
};

export const getCreditCardIcon = (brand = null) => {
     if (!brand) return "/images/cc-icons/default.svg";
     else if (brand === "amex") return "/images/cc-icons/amex.svg";
     else if (brand === "diners") return "/images/cc-icons/diners_club.svg";
     else if (brand === "discover") return "/images/cc-icons/discover.svg";
     else if (brand === "jcb") return "/images/cc-icons/jcb.svg";
     else if (brand === "mastercard") return "/images/cc-icons/mastercard.svg";
     else if (brand === "unionpay") return "/images/cc-icons/unionpay.svg";
     else if (brand === "visa") return "/images/cc-icons/visa.svg";
     else return "/images/cc-icons/default.svg";
};

export const timeout = (ms) => {
     return new Promise((resolve) => setTimeout(resolve, ms));
};

export const stripeIntervalToAbbreviation = (unit) => {
     const abbreviation = {
          day: "day",
          week: "wk",
          month: "mo",
          year: "yr",
     };
     return abbreviation[unit];
};
