import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import CheckoutWrapper from "./pages/CheckoutWrapper/CheckoutWrapper";
import Home from "./pages/Home";
import RequestAccess from "./pages/RequestAccess";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";

const queryClient = new QueryClient({
     defaultOptions: {
          queries: {
               refetchOnWindowFocus: false,
          },
     },
});

function App() {
     return (
          <QueryClientProvider client={queryClient}>
               <Router>
                    <Routes>
                         <Route path="/" element={<Home />} />
                         <Route path="/request-access" element={<RequestAccess />} />
                         <Route path="/:provider/checkout/:invoice_id" element={<CheckoutWrapper />} />
                    </Routes>
               </Router>
               {/* <ReactQueryDevtools /> */}
          </QueryClientProvider>
     );
}

export default App;
