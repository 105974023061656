import React, { useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "./style.css";
import { useLocation } from "react-router-dom";

export default function Home() {
     const stripeClientId = "ca_MPkW2fN2tvqvg0N73FDAg3ztwHHpiR4u";
     const stripeOAuthLink =
          "https://connect.stripe.com/oauth/authorize?response_type=code&client_id=" +
          stripeClientId +
          "&scope=read_write";
     const location = useLocation();

     useEffect(() => {
          if (location.state && location.state.requestSuccess === true) {
               toast.success("Stripe connection successfull.", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
               });
          } else if (
               location.state &&
               location.state.requestSuccess === false
          ) {
               toast.error("An error occured during the connection.", {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: true,
                    progress: undefined,
               });
          }

          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, []);

     return (
          <div className="main-div">
               <ToastContainer />
               <div>
                    <header className="main-header">
                         <div className="nav-header">
                              <div className="logo">
                                   <h1>ZAP</h1>
                              </div>
                              <div className="">
                                   <a
                                        className="connect-btn"
                                        href={stripeOAuthLink}
                                   >
                                        CONNECT
                                   </a>
                              </div>
                         </div>
                    </header>
               </div>
               <main className="home-page">
                    <div className="frictionless-h">
                         <h1>
                              FRICTIONLESS <br /> PAYWALL
                         </h1>
                    </div>
                    <video
                         width="640"
                         height="640"
                         loop={true}
                         autoPlay
                         src={require("../../assets/videos/ecommerce.mp4")}
                         type="video/mp4"
                         muted
                         playsInline
                    ></video>
                    <div className="mobile-connect-button">
                         <a className="mbl-connect-btn" href={stripeOAuthLink}>
                              CONNECT
                         </a>
                    </div>
               </main>
               <div>
                    <footer className="main-footer">
                         <div className="footer-bottom">
                              <div className="left-text">EIN: 85-0601775</div>
                              <div className="copyright">
                                   © 2022 ZAP PLUGIN LLC, ALL RIGHTS RESERVED
                              </div>
                              <div className="right-text">ZAP</div>
                         </div>
                    </footer>
               </div>
          </div>
     );
}
