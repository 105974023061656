import React from "react";
import styled from "styled-components";
import { useContext } from "react";
import { CheckoutContext } from "../../../pages/CheckoutWrapper/CheckoutWrapper";
import { ReactSVG } from "react-svg";
import { BASE_URL, PROVIDER_SVG_FOLDER } from "config/config";
import { useParams } from "react-router-dom";

const EmailInputContainer = styled.div`
     width: 100%;
     height: 80px;
     padding-left: 16px;
     padding-right: 16px;
     margin-top: 16px;
`;

const EmailInputContent = styled.form`
     position: relative;
     overflow: hidden;
     width: 100%;
     height: 100%;
     padding: 16px;
     background: ${(props) => props.theme.emailForm.inputBg};
     border-radius: ${(props) => props.theme.input.br};
     display: flex;
     flex-direction: row;
     align-items: center;
`;

const EmailInputLogoContainer = styled.div`
     position: absolute;
     z-index: 2;
     background: ${(props) => props.theme.emailForm.inputBg};
     top: 0;
     left: 0;
     bottom: 0;
     height: 100%;
     padding: 20px;
     display: flex;
     justify-content: center;
     align-items: center;
     svg {
          height: ${(props) => props.theme.emailForm.logoHeight};
     }
     div {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
     }
`;

const EmailInputField = styled.input`
     position: absolute;
     right: 0;
     left: 0;
     padding: 20px;
     top: 50%;
     transform: translateY(-50%);
     text-align: right;
     font-size: ${(props) => props.theme.input.fontSize};
     color: ${(props) => props.theme.emailForm.inputFontColor};
     background: transparent;
     border: none;
     font-family: "Provider";

     &::placeholder {
          color: ${(props) => props.theme.emailForm.inputFontColor};
          opacity: 0.5;
     }

     &:focus {
          outline: none;
     }
`;
export default function EmailInput({ onBlur = undefined }) {
     const { providerConfig } = useContext(CheckoutContext);
     const { provider } = useParams();

     // Scroll to top on input focus
     // (used to prevent iOs from hiding the provider logo)
     const handleFocus = () => {
          setTimeout(function () {
               window.scrollTo({
                    top: 100,
                    behavior: "smooth",
               });
          }, 80);
     };

     return (
          <EmailInputContainer>
               <EmailInputContent
                    onSubmit={(e) => {
                         document.activeElement.blur();
                         e.preventDefault();
                    }}
               >
                    <EmailInputLogoContainer>
                         <ReactSVG
                              src={BASE_URL + PROVIDER_SVG_FOLDER + provider + "/" + providerConfig.logo.email.filename}
                         />
                    </EmailInputLogoContainer>
                    <EmailInputField
                         type="email"
                         onFocus={handleFocus}
                         onBlur={(e) => onBlur(e)}
                         placeholder="user@email.com"
                    />
               </EmailInputContent>
          </EmailInputContainer>
     );
}
