import React from "react";
import styled, { useTheme } from "styled-components";
import ContentLoader from "react-content-loader";

const PlaceholderCartItemContainer = styled.div`
     width: 100%;
     position: relative;
     display: flex;
     gap: 16px;
     flex-direction: row;
     padding-bottom: 32px;
     overflow: hidden;
     opacity: 0.5;
`;

const PlaceholderCartItemImageContainer = styled.div`
     height: 100px;
     width: 100px;
     border-radius: 10px;
     background: #d5d5d5;
     position: relative;
     overflow: hidden;
`;

const PlaceholderCartItemDescription = styled.div`
     display: flex;
     justify-content: space-between;
`;

const PlaceholderCartItemTitle = styled.div`
     height: 16px;
     width: 50%;
     border-radius: 10px;
     background: #d5d5d5;
`;

const PlaceholderCartItemPrice = styled.div`
     height: 16px;
     width: 30%;
     border-radius: 10px;
     background: #d5d5d5;
`;

const PlaceholderCartItemInfoContainer = styled.div`
     flex-grow: 1;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     gap: 16px;
     border-radius: 10px;
`;

const PlaceholderCartItemParametersContainer = styled.div`
     height: 16px;
     border-radius: 10px;
     display: flex;
     justify-content: space-between;
`;

const PlaceholderCartItemParameter = styled.div`
     display: flex;
     flex-direction: row;
     gap: 10px;
`;

const PlaceholderCartItemParameterText = styled.div`
     height: 16px;
     width: 32px;
     border-radius: 10px;
     background: #d5d5d5;
`;

const PlaceholderCartItemParameterArrow = styled.div`
     height: 16px;
     width: 16px;
     border-radius: 10px;
     background: #d5d5d5;
`;

const PlaceholderCartItemParameterSpacer = styled.div`
     height: 16px;
`;

export default function PlaceholderCartItem() {
     const theme = useTheme();

     return (
          <ContentLoader
               viewBox="0 0 380 100"
               foregroundColor={theme.bg}
               speed={2}
               backgroundColor="#e8e8e8"
               backgroundOpacity={0.5}
               foregroundOpacity={0.5}
          >
               <rect x="0" y="0" rx="5" ry="5" width="100" height="100" />
               <rect x="120" y="0" rx="4" ry="4" width="150" height="13" />
               <rect x="330" y="0" rx="4" ry="4" width="50" height="13" />

               <rect x="120" y="40" rx="3" ry="3" width="60" height="10" />
               <rect x="220" y="40" rx="3" ry="3" width="60" height="10" />
               <rect x="320" y="40" rx="3" ry="3" width="60" height="10" />
          </ContentLoader>
     );
}
