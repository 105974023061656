import React, { useState } from "react";
import styled, { css } from "styled-components";
import CardNotch from "../CardNotch/CardNotch";
import EnterButton from "../EnterButton/EnterButton";
import { AddressAutofill, config as mapboxconfig } from "@mapbox/search-js-react";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import hexToRgba from "hex-to-rgba";
import { MAPBOX_PK } from "config/config";
import { useCallback } from "react";
import { CheckoutContext } from "pages/CheckoutWrapper/CheckoutWrapper";
import { FormatPrice, FormatShippingRateDeliveryEstimate } from "../../utils/utils";
import { toast } from "react-toastify";

var cn = require("classnames");

const BillingCardContent = styled.div`
     position: absolute;
     width: 100%;
     height: calc(100% - 32px);
     overflow: hidden;
     top: ${(props) => (props.center ? "35%" : "initial")};
`;
const BillingCardContainer = styled.div`
     position: absolute;
     top: 0;
     z-index: 500;
     height: 100%;
     width: 100%;
     overflow: hidden;
     font-family: "Provider";

     & ${BillingCardContent} {
          transition: opacity 0.3s cubic-bezier(0.76, 0, 0.24, 1) 1.1s;
          opacity: 1;
     }

     ${(props) =>
          props.active
               ? ""
               : css`
                      ${closedStyles}
                 `}
`;

const closedStyles = `
     > div {
          top: 100%;
     }

     & ${BillingCardContent} {
          transition: opacity .1s cubic-bezier(0.76, 0, 0.24, 1) 1.2s;
          opacity: 0;
     }
     pointer-events: none;
`;

const BillingCardContentContainer = styled.div`
     transition: top 1.2s cubic-bezier(0.76, 0, 0.24, 1);
     position: absolute;
     top: 32px;
     height: calc(100% - 32px);
     overflow: hidden;
     width: 100%;

     background: ${(props) => props.theme.card.bg};
     border-radius: ${(props) => props.theme.card.br};
     color: ${(props) => props.theme.font_color};

     input {
          color: ${(props) => props.theme.font_color};
     }
`;

const BillingInputContainer = styled.div`
     padding: 0px 40px 0 40px;
     overflow: visible;
     .zap-mb-input {
          width: 100%;
          border: none;
          font-family: "provider";
          margin-top: 0.5em;
          opacity: 0.3;
          font-size: 1.1em;
          background-color: transparent;
          padding: 0 !important;

          &:focus {
               opacity: 1;
          }
     }
`;

const DeliveryMethodContainer = styled.div`
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     padding: 40px 40px 0 40px;
`;

const DeliveryMethodTitle = styled.h1`
     font-size: 2em;
     transition: all 0.3s cubic-bezier(0.76, 0, 0.24, 1);
     opacity: ${(props) => (props.selected ? 1 : 0.3)};
`;

const ShippingRatesContainer = styled.div`
     padding: 40px;
     padding-top: 50px;
     display: flex;
     flex-direction: column;
     gap: 30px;
`;
const ShippingRateOption = styled.div`
     font-size: 24px;
     white-space: nowrap;
     transition: all 0.3s cubic-bezier(0.76, 0, 0.24, 1);
     opacity: ${(props) => (props.selected ? 1 : 0.2)};
`;

export default function BillingCard() {
     const theme = useContext(ThemeContext);
     const {
          billingAddress,
          setBillingAddress,
          isBillingCardOpen,
          setIsBillingCardOpen,
          providerConfig,
          shippingRates,
          shippingRate,
          setShippingRate,
          lockedShippingRate,
     } = useContext(CheckoutContext);

     const [selectedMethod, setSelectedMethod] = useState("delivery");

     const defaultAutofillValue = billingAddress ? billingAddress.line1 : undefined;

     const handleRetrieve = useCallback(
          (res) => {
               const feature = res.features[0];
               const address = {
                    city: feature.properties.place,
                    country: feature.properties.country,
                    line1: feature.properties.address_line1,
                    line2: feature.properties.address_line2,
                    postal_code: feature.properties.postcode,
                    state: feature.properties.region,
               };

               setBillingAddress(address);
          },
          [setBillingAddress]
     );

     const mapboxTheme = {
          variables: {
               colorBackground: theme.card.bg,
               colorText: theme.font_color,
               colorSecondary: theme.font_color,
               colorPrimary: theme.font_color,
               colorBackgroundActive: hexToRgba(theme.font_color, 0.2),
               colorBackgroundHover: hexToRgba(theme.font_color, 0.2),
          },
     };
     return (
          <BillingCardContainer active={isBillingCardOpen}>
               <BillingCardContentContainer>
                    <CardNotch onClick={() => setIsBillingCardOpen(false)} />
                    <BillingCardContent center={!providerConfig.use_delivery}>
                         {!!providerConfig.use_delivery && (
                              <DeliveryMethodContainer>
                                   <DeliveryMethodTitle
                                        selected={selectedMethod === "delivery"}
                                        onClick={() => setSelectedMethod("delivery")}
                                   >
                                        Delivery
                                   </DeliveryMethodTitle>

                                   {!!providerConfig.use_pickup && (
                                        <DeliveryMethodTitle
                                             selected={selectedMethod === "pickup"}
                                             onClick={() => setSelectedMethod("pickup")}
                                        >
                                             Pickup
                                        </DeliveryMethodTitle>
                                   )}
                              </DeliveryMethodContainer>
                         )}

                         <BillingInputContainer>
                              Address
                              <form>
                                   <AddressAutofill
                                        theme={mapboxTheme}
                                        accessToken={MAPBOX_PK}
                                        onRetrieve={handleRetrieve}
                                   >
                                        <input
                                             placeholder="Ex : 1220 Oak Street, Los Angeles"
                                             type="text"
                                             autoComplete="address-line1"
                                             className="zap-mb-input"
                                             defaultValue={defaultAutofillValue}
                                        />
                                   </AddressAutofill>
                              </form>
                         </BillingInputContainer>

                         {!!providerConfig.use_delivery && (
                              <ShippingRatesContainer className="container">
                                   {shippingRates.map((shipping_rate, index) => {
                                        if (
                                             (selectedMethod === "delivery" && !!shipping_rate.metadata.pickup) ||
                                             (selectedMethod === "pickup" && !shipping_rate.metadata.pickup)
                                        )
                                             return;

                                        return (
                                             <ShippingRateOption
                                                  key={index}
                                                  selected={[shippingRate?.id, lockedShippingRate].includes(
                                                       shipping_rate.id
                                                  )}
                                                  onClick={() => {
                                                       if (
                                                            lockedShippingRate &&
                                                            shipping_rate.id !== lockedShippingRate
                                                       ) {
                                                            toast.error("The shipping method cannot be changed", {
                                                                 position: "top-center",
                                                                 autoClose: 3000,
                                                                 hideProgressBar: true,
                                                                 closeOnClick: true,
                                                                 draggable: false,
                                                                 toastId: "shipping-rate-toast",
                                                            });
                                                       } else if (!lockedShippingRate) setShippingRate(shipping_rate);
                                                  }}
                                             >
                                                  {shipping_rate.display_name} (
                                                  {FormatPrice(
                                                       shipping_rate.fixed_amount.amount,
                                                       shipping_rate.fixed_amount.currency
                                                  )}
                                                  )
                                                  <br />
                                                  {FormatShippingRateDeliveryEstimate(shipping_rate.delivery_estimate)}
                                             </ShippingRateOption>
                                        );
                                   })}
                              </ShippingRatesContainer>
                         )}

                         <EnterButton
                              onClick={() => setIsBillingCardOpen(false)}
                              enabled={!!billingAddress}
                              absolute={providerConfig.use_delivery}
                         />
                    </BillingCardContent>
               </BillingCardContentContainer>
          </BillingCardContainer>
     );
}
