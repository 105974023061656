import React from "react";
import { useState } from "react";
import styled from "styled-components";

const CardNotchContainer = styled.div`
     width: 100%;
     height: 10px;
     margin-top: 16px;

     display: flex;
     align-items: center;
     justify-content: center;
`;
const CardNotchContent = styled.button`
     height: 100%;
     width: 50px;
     border: none;
     border-radius: 9999px;
     background: ${(props) => props.theme.card.notch.bg};
     opacity: ${(props) => props.theme.card.notch.opacity};
     transition: opacity 0.1s cubic-bezier(0.76, 0, 0.24, 1);

     &:active {
          opacity: calc(${(props) => props.theme.card.notch.opacity} * 1.6);
     }
`;
export default function CardNotch({ onClick = () => {} }) {
     const [xDown, setXDown] = useState(null);
     const [yDown, setYDown] = useState(null);

     // Detect touch slide down to close the card
     // (code: https://stackoverflow.com/questions/2264072/detect-a-finger-swipe-through-javascript-on-the-iphone-and-android)
     const getTouches = (e) => {
          return e.touches || e.originalEvent.touches;
     };
     const handleTouchStart = (e) => {
          const firstTouch = getTouches(e)[0];
          setXDown(firstTouch.clientX);
          setYDown(firstTouch.clientY);
     };

     const handleTouchMove = (e) => {
          if (!xDown || !yDown) {
               return;
          }

          var xUp = e.touches[0].clientX;
          var yUp = e.touches[0].clientY;

          var xDiff = xDown - xUp;
          var yDiff = yDown - yUp;

          if (Math.abs(xDiff) < Math.abs(yDiff)) {
               if (yDiff < 0) {
                    onClick();
                    setXDown(null);
                    setYDown(null);
               }
          }
     };

     return (
          <CardNotchContainer onClick={onClick} onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
               <CardNotchContent></CardNotchContent>
          </CardNotchContainer>
     );
}
