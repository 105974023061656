exports.theme = {
     bg: "#000",
     background_image: "",
     bg_processing: "#000",
     loader_color: "#FFF",
     font_color: "#FFF",
     progress_bar_color: "#1ed760",
     font_size: "16px",
     card: {
          shadow: "rgba(0, 0, 0, 0.2)",
          br: "32px 32px 0 0",
          bg: "#000",
          notch: {
               bg: "#FFF",
               opacity: ".2",
          },
     },
     emailForm: {
          bg: "#000",
          br: "32px 32px 0 0",
          shadow: "rgba(0, 0, 0, 0.2)",
          inputBg: "#1ed760",
          inputFontColor: "#FFF",
          logoHeight: "80%",
     },
     input: {
          br: "999px",
          bg: "#1ed760",
          fontSize: "18px",
          fontColor: "#FFF",
          arrowColor: "#FFF",
     },
     logo: {
          height: {
               email: "90px",
               cart: "50px",
               subscription: "80px",
               processing: "50px",
          },
          styles: {},
     },
};
