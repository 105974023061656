import React from "react";
import EmailForm from "../../components/EmailForm/EmailForm";
import "../../global.css";
import { useState } from "react";
import ProviderLogo from "../../components/ProviderLogo/ProviderLogo";
import Footer from "../../components/Footer/Footer";
import BackButton from "../../components/BackButton/BackButton";
import styled, { css } from "styled-components";
import PaymentMethodCard from "../../components/PaymentMethodCard/PaymentMethodCard";
import BillingCard from "../../components/BillingCard/BillingCard";
import { CheckoutContext } from "pages/CheckoutWrapper/CheckoutWrapper";
import { useContext } from "react";
import Loader from "./Loader";
import { useTheme } from "styled-components";
import CartItemsList from "components/CartItemsList/CartItemsList";
import ProgressBar from "components/ProgressBar/ProgressBar";
import { useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { payInvoice } from "api/stripe";
import { useStripe } from "@stripe/react-stripe-js";
import { timeout } from "utils/utils";
import DesktopOverlay from "components/DesktopOverlay/DesktopOverlay";

const StyledToastContainer = styled(ToastContainer)`
     &&&.Toastify__toast-container {
     }
     .Toastify__toast {
          background: ${(props) => props.theme.card.bg};
          color: ${(props) => props.theme.font_color};
     }
     .Toastify__toast-body {
     }
     .Toastify__progress-bar {
     }
`;

const CheckoutContainer = styled.div`
     position: relative;
     height: 100%;
     width: 100vw;
     overflow: hidden;
     transition: background-color
          ${(props) => (props.isProcessing ? "2s" : "1s")}
          cubic-bezier(0.76, 0, 0.24, 1);
     transition-delay: ${(props) => (props.isProcessing ? "1s" : "0s")};
     background-color: ${(props) =>
          props.isProcessing ? props.theme.bg_processing : props.theme.bg};
     background-position: center;
     background-size: cover;
     background-repeat: no-repeat;

     ${(props) => props.theme.global_style}

     :root {
          overflow-y: hidden !important;
     }

     &:before {
          content: " ";
          position: absolute;
          transition: transform ${(props) => (props.isProcessing ? "2s" : "1s")}
               cubic-bezier(0.76, 0, 0.24, 1)
               ${(props) => (props.isProcessing ? "1s" : "0s")};
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;

          ${(props) =>
               !!props.theme.background_image
                    ? css`
                           background-image: linear-gradient(
                                     to bottom,
                                     rgba(0, 0, 0, 0.306457093) 0%,
                                     #000000 75%
                                ),
                                ${(props) =>
                                     "url('" +
                                     props.theme.background_image +
                                     "')"};
                      `
                    : ""}
          background-size: cover;
          background-position: center center;
          transform: scale(${(props) => (props.isProcessing ? 1.1 : 1)});
     }
`;

export default function Checkout() {
     const {
          currentStatus,
          setCurrentStatus,
          displayType,
          invoice,
          customer,
          paymentMethod,
          billingAddress,
          isPaymentSuccessful,
          setIsPaymentSuccessful,
          providerConfig,
          shippingRate,
          lockedShippingRate,
          setLockedShippingRate,
     } = useContext(CheckoutContext);
     const { provider } = useParams();
     const theme = useTheme();
     const stripe = useStripe();

     const sendResponse = (invoice_id, success = false) => {
          if (!window.parent) return;
          const message = {
               type: "zapResponse",
               invoice_id: invoice_id,
               success: success,
          };
          window.parent.postMessage(message, "*");
     };

     const handlePayInvoice = async () => {
          setCurrentStatus("processing");

          if (process.env.NODE_ENV === "development") {
               await timeout(4000);
               setIsPaymentSuccessful(true);
               sendResponse(invoice.id, true);
               await timeout(5000);
               setCurrentStatus(displayType);
               setIsPaymentSuccessful(false);
               return;
          }

          // Small timeout to let logo animation finish
          await timeout(1500);

          let shippingRateID;
          if (!!invoice.subscription || !providerConfig.use_delivery) {
               shippingRateID = null;
          } else {
               shippingRateID = lockedShippingRate
                    ? lockedShippingRate
                    : shippingRate.id;
          }

          const response = await payInvoice(
               invoice.id,
               paymentMethod.id,
               customer.id,
               billingAddress,
               provider,
               providerConfig.use_delivery,
               shippingRateID
          );
          if (response.success) {
               setIsPaymentSuccessful(true);
               sendResponse(invoice.id, true);
               setTimeout(() => {
                    window.location.replace(providerConfig.redirect_url);
               }, 2000);
          } else if (
               !response.success &&
               response.error === "requires_action"
          ) {
               setLockedShippingRate(shippingRateID);
               stripe
                    .confirmCardPayment(response.paymentIntent.client_secret, {
                         payment_method: paymentMethod.id,
                    })
                    .then((result) => {
                         if (result.paymentIntent) {
                              setIsPaymentSuccessful(true);
                              sendResponse(invoice.id, true);
                              setTimeout(() => {
                                   window.location.replace(
                                        providerConfig.redirect_url
                                   );
                              }, 2000);
                         } else {
                              setCurrentStatus(displayType);
                              toast.error("Your payment has been declined", {
                                   position: "top-center",
                                   autoClose: 3000,
                                   hideProgressBar: true,
                                   closeOnClick: true,
                                   draggable: false,
                                   toastId: "pay-invoice-toast",
                              });
                         }
                    });
          } else {
               setCurrentStatus(displayType);
               toast.error("Your payment has been declined", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: false,
                    toastId: "pay-invoice-toast",
               });
          }
     };

     return (
          <CheckoutContainer isProcessing={currentStatus === "processing"}>
               <DesktopOverlay />
               <StyledToastContainer />
               <ProgressBar />
               <EmailForm />
               <BackButton />
               <ProviderLogo />
               {displayType === "cart" && <CartItemsList />}
               <BillingCard />
               <PaymentMethodCard />
               <Footer onPayButtonClick={handlePayInvoice} />

               <Loader
                    isVisible={currentStatus === "processing"}
                    isLoading={true}
                    color={theme.loader_color}
                    isReady={isPaymentSuccessful}
               />
          </CheckoutContainer>
     );
}
