exports.theme = {
     bg: "#000",
     background_image: "",
     bg_processing: "#000",
     loader_color: "#FFF",
     font_color: "#FFF",
     progress_bar_color: "#fff",
     font_size: "16px",
     card: {
          shadow: "rgba(0, 0, 0, 0.2)",
          br: "32px 32px 0 0",
          bg: "#000",
          notch: {
               bg: "#FFF",
               opacity: ".2",
          },
     },
     emailForm: {
          bg: "#000",
          br: "32px 32px 0 0",
          shadow: "rgba(0, 0, 0, 0.2)",
          inputBg: "#fff",
          inputFontColor: "#000",
          logoHeight: "80%",
     },
     input: {
          br: "999px",
          bg: "#fff",
          fontSize: "18px",
          fontColor: "#000",
          arrowColor: "#FFF",
     },
     logo: {
          height: {
               email: "55px",
               cart: "60px",
               subscription: "50px",
               processing: "40px",
          },
          styles: {
               processing: `
                    margin-top: -10px;
               `,
          },
     },
};
