import React from "react";
import styled from "styled-components";
import { CheckoutContext } from "pages/CheckoutWrapper/CheckoutWrapper";
import { useContext } from "react";
import { FormatPrice, stripeIntervalToAbbreviation } from "../../../utils/utils";
import { useEffect } from "react";
import { payInvoice } from "api/stripe";
import { useParams } from "react-router-dom";
var cn = require("classnames");

const PayButtonContainer = styled.div`
     width: 100%;

     display: flex;
     justify-content: center;
     align-items: center;
     transition: opacity 0.6s cubic-bezier(0.76, 0, 0.24, 1), transform 0.1s cubic-bezier(0.76, 0, 0.24, 1);

     opacity: ${(props) => (!props.enabled ? 0.4 : 1)};
     pointer-events: ${(props) => (!props.enabled ? "none" : "all")};
`;
const PayButtonContent = styled.button`
     width: 100%;
     height: 100%;
     height: 80px;
     background: ${(props) => props.theme.input.bg};
     font-size: ${(props) => props.theme.input.fontSize};
     border-radius: ${(props) => props.theme.input.br};
     color: ${(props) => props.theme.input.fontColor};

     border: none;

     display: flex;
     justify-content: space-between;
     align-items: center;
     font-family: "Provider";
     padding: 24px;

     transition: transform 0.05s cubic-bezier(0.76, 0, 0.24, 1), opacity 0.6s cubic-bezier(0.76, 0, 0.24, 1);

     &:active {
          transform: scale(0.97);
     }
`;

const PayButtonTitle = styled.div``;

const PayButtonAmount = styled.div``;
const PaymentInterval = styled.span`
     opacity: 0.5;
`;

export default function PayButton({ onClick }) {
     const {
          invoice,
          customer,
          paymentMethod,
          billingAddress,
          subscription,
          shippingRate,
          lockedShippingRate,
          providerConfig,
     } = useContext(CheckoutContext);

     const interval = subscription ? subscription.items.data[0].price.recurring.interval : null;
     return (
          <PayButtonContainer
               onClick={onClick}
               enabled={
                    invoice &&
                    customer &&
                    paymentMethod &&
                    (providerConfig.use_delivery
                         ? (!!shippingRate || !!lockedShippingRate) && !!billingAddress
                         : !!billingAddress)
               }
          >
               <PayButtonContent>
                    <PayButtonTitle>Charge</PayButtonTitle>
                    <PayButtonAmount>
                         {FormatPrice(
                              invoice.amount_due +
                                   (shippingRate?.fixed_amount?.amount ? shippingRate.fixed_amount.amount : 0),
                              invoice.currency
                         )}
                         {!!interval && <PaymentInterval>/{stripeIntervalToAbbreviation(interval)}</PaymentInterval>}
                    </PayButtonAmount>
               </PayButtonContent>
          </PayButtonContainer>
     );
}
