import { CheckoutContext } from "pages/CheckoutWrapper/CheckoutWrapper";
import React, { useContext } from "react";
import styled from "styled-components";
import CartItem from "./CartItem/CartItem";
import PlaceholderCartItem from "./CartItem/PlaceholderCartItem";

const handleCartTopOffset = (status, logo_cart_height) => {
     switch (status) {
          case "email":
               return "50px";
          case "cart":
               return "calc(32px + " + logo_cart_height + " + 16px);";
          case "processing":
               return "calc(32px + " + logo_cart_height + " + 16px);";
          default:
               return "50px";
     }
};

const handleCartOpacity = (status) => {
     switch (status) {
          case "cart":
               return "1";
          case "email":
               return ".2";
          case "processing":
               return "0";
          default:
               return "0";
     }
};

const CartItemsListContainer = styled.div`
     position: absolute;
     top: ${(props) => handleCartTopOffset(props.status, props.theme.logo.height.cart)};
     width: 100%;
     height: ${(props) => "calc(100% - " + props.theme.logo.height.cart + " - 64px - 260px)"};
     transition: opacity 1s cubic-bezier(0.65, 0, 0.35, 1) ${(props) => (props.status === "cart" ? "1s" : "0s")},
          top 1s cubic-bezier(0.65, 0, 0.35, 1) ${(props) => (props.status === "email" ? "1s" : "0s")};
     opacity: ${(props) => handleCartOpacity(props.status)};
`;
const CartItemsScrollList = styled.div`
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     transition: max-height 1s cubic-bezier(0.65, 0, 0.35, 1) ${(props) => (props.status === "email" ? "1s" : "0s")};
     max-height: ${(props) => (props.status === "email" ? "300vh" : "100%")};
     overflow: scroll;
     padding: 0 24px 0 24px;
     display: flex;
     flex-direction: column;
     gap: 32px;
`;

export default function CartItemsList() {
     const { currentStatus, invoice, products } = useContext(CheckoutContext);

     if (!products) {
          return (
               <CartItemsListContainer status={currentStatus}>
                    <CartItemsScrollList>
                         {[...Array(invoice.lines.total_count)].map((e, i) => {
                              return <PlaceholderCartItem key={i} />;
                         })}
                    </CartItemsScrollList>
               </CartItemsListContainer>
          );
     } else {
          return (
               <CartItemsListContainer status={currentStatus}>
                    <CartItemsScrollList status={currentStatus}>
                         {[...Array(invoice.lines.data)][0].map((item, invoiceItemIndex) => {
                              if (item.metadata.shipping_rate) return;
                              return <CartItem invoiceItemIndex={invoiceItemIndex} key={item.id} />;
                         })}
                    </CartItemsScrollList>
               </CartItemsListContainer>
          );
     }
}
