import axios from "axios";
const api_key = process.env.REACT_APP_REDCIRCLE_API_KEY;

export const fetchTargetProductInfo = async (stripe_product) => {
     const { tcin } = stripe_product.metadata;
     if (!tcin) return null;
     const params = {
          api_key: api_key,
          type: "product",
          tcin: tcin,
     };

     const res = await axios.get("https://api.redcircleapi.com/request", { params });
     if (res.error) return null;

     const product_info = {
          main_image: res.data.product.main_image.link,
          stripe_id: stripe_product.id,
     };
     return product_info;
};
