import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";
import { useParams } from "react-router-dom";
import Loader from "../Checkout/Loader";
import Checkout from "../Checkout/Checkout";
import {
     fetchAccountId,
     fetchCustomer,
     fetchInvoice,
     fetchInvoiceProducts,
     fetchPaymentMethod,
     fetchPaymentMethods,
     fetchShippingRates,
     fetchSubscription,
} from "../../api/stripe";

import { useQuery } from "react-query";
import { useState, createContext } from "react";
import { ThemeProvider } from "styled-components";

import { BASE_URL } from "config/config";
import InvalidInvoice from "pages/InvalidInvoice/InvalidInvoice";
import { useEffect } from "react";
import { executeFunctionByName } from "api/products";
import * as products_api from "api/products";

export const CheckoutContext = createContext();

export default function CheckoutWrapper() {
     const { provider, invoice_id } = useParams();

     let providerConfigFile;
     let theme = undefined;

     try {
          providerConfigFile = require("../../config/provider/" +
               provider +
               ".json");
          const themeFile = require("../../themes/brands/" + provider + ".js");
          theme = { ...themeFile.theme };
     } catch {
          providerConfigFile = null;
     }

     const [providerConfig, setProviderConfig] = useState(providerConfigFile);
     const [shippingRates, setShippingRates] = useState(null);
     const [lockedShippingRate, setLockedShippingRate] = useState(null);
     const [shippingRate, setShippingRate] = useState(null);
     const [customer, setCustomer] = useState(null);
     const [invoice, setInvoice] = useState(null);
     const [products, setProducts] = useState(null);
     const [productsInfo, setProductsInfo] = useState(null);
     const [subscription, setSubscription] = useState(null);
     const [currentStatus, setCurrentStatus] = useState("email");
     const [billingAddress, setBillingAddress] = useState(null);

     const [paymentMethod, setPaymentMethod] = useState(null);
     const [paymentMethods, setPaymentMethods] = useState([]);

     const [displayType, setDisplayType] = useState("subscription");
     const [isEmailFormOpen, setIsEmailFormOpen] = useState(true);
     const [isPaymentMethodCardOpen, setIsPaymentMethodCardOpen] =
          useState(false);
     const [isBillingCardOpen, setIsBillingCardOpen] = useState(false);
     const [isPaymentSuccessful, setIsPaymentSuccessful] = useState(false);

     const checkoutContextValue = {
          providerConfig,
          setProviderConfig,
          shippingRates,
          setShippingRates,
          shippingRate,
          setShippingRate,
          lockedShippingRate,
          setLockedShippingRate,
          invoice,
          setInvoice,
          customer,
          setCustomer,
          currentStatus,
          setCurrentStatus,
          billingAddress,
          setBillingAddress,
          products,
          setProducts,
          productsInfo,
          setProductsInfo,
          subscription,
          setSubscription,
          isBillingCardOpen,
          setIsBillingCardOpen,
          displayType,
          setDisplayType,
          isEmailFormOpen,
          setIsEmailFormOpen,
          isPaymentMethodCardOpen,
          setIsPaymentMethodCardOpen,
          paymentMethod,
          setPaymentMethod,
          paymentMethods,
          setPaymentMethods,
          isPaymentSuccessful,
          setIsPaymentSuccessful,
     };

     const { data: account_id, isError: accountIdError } = useQuery(
          "accountId",
          () => fetchAccountId(provider)
     );

     const {
          isError: isInvoiceError,
          isIdle: isInvoiceIdle,
          isLoading: isInvoiceLoading,
     } = useQuery({
          queryKey: "invoice",
          queryFn: () => fetchInvoice(invoice_id, provider),
          enabled: !!account_id,
          onSuccess: (invoice_response) => {
               setInvoice(invoice_response);
               if (
                    !invoice_response.subscription &&
                    providerConfigFile?.show_cart_items
               )
                    setDisplayType("cart");

               // Check if invoice has locked shipping rate
               const invoiceShippingRate = invoice_response.lines.data.find(
                    (element) => {
                         return element.metadata.shipping_rate;
                    }
               );
               if (invoiceShippingRate && invoice_response.status !== "draft") {
                    setLockedShippingRate(
                         invoiceShippingRate.metadata.shipping_rate
                    );
               }
          },
     });

     const {
          isError: isShippingRatesError,
          isIdle: isShippingRatesIdle,
          isLoading: isShippingRatesLoading,
     } = useQuery({
          queryKey: "shipping_rates",
          queryFn: () => fetchShippingRates(provider),
          enabled: !!providerConfigFile?.use_delivery,
          onSuccess: (shipping_rates_response) => {
               setShippingRates(shipping_rates_response.data);
          },
     });

     const {
          isError: isCustomerError,
          isIdle: isCustomerIdle,
          isLoading: isCustomerLoading,
     } = useQuery({
          queryKey: "customer",
          queryFn: () => fetchCustomer(invoice.customer, provider),
          enabled: !!invoice,
          onSuccess: (customer_response) => {
               setCustomer(customer_response);
               if (
                    !!customer_response.shipping &&
                    customer_response.shipping.address.line1 !== ""
               )
                    setBillingAddress({
                         ...customer_response.shipping.address,
                    });
          },
     });

     useQuery({
          queryKey: "paymentmethod",
          queryFn: () =>
               fetchPaymentMethod(
                    customer.invoice_settings.default_payment_method,
                    provider
               ),
          enabled:
               !!customer && !!customer.invoice_settings.default_payment_method,
          onSuccess: (payment_method_response) => {
               if (payment_method_response.type === "card")
                    setPaymentMethod(payment_method_response);
          },
     });

     const { isError: isSubscriptionError, isIdle: isSubscriptionIdle } =
          useQuery({
               queryKey: "subscription",
               queryFn: () => fetchSubscription(invoice.subscription, provider),
               enabled: !!invoice && !!invoice.subscription,
               onSuccess: (subscription_response) => {
                    setSubscription(subscription_response);
               },
          });

     // Fetch invoice products
     useQuery({
          queryKey: "products",
          queryFn: () => fetchInvoiceProducts(invoice_id, provider),
          onSuccess: (res) => {
               setProducts(res);
          },
     });

     // Fetch payment methods
     useQuery({
          queryKey: "payment-methods",
          queryFn: () => fetchPaymentMethods(customer.id, provider),
          enabled: !!customer,
          onSuccess: (res) => {
               setPaymentMethods([...res.data]);
          },
     });

     const StripeObject = loadStripe(
          process.env.NODE_ENV === "production"
               ? process.env.REACT_APP_STRIPE_PUBLISHABLE_PROD
               : process.env.REACT_APP_STRIPE_PUBLISHABLE_TEST,
          {
               stripeAccount: account_id,
          }
     );

     // const StripeObject = loadStripe(
     //      process.env.REACT_APP_STRIPE_PUBLISHABLE_PROD,
     //      {
     //           stripeAccount: account_id,
     //      }
     // );

     const StripeOptions = {
          fonts: [
               {
                    family: "ProviderFont",
                    src:
                         "url(" +
                         BASE_URL +
                         "assets/fonts/" +
                         providerConfigFile?.font_filename +
                         ")",
               },
          ],
     };

     useEffect(() => {
          const getProductsInfo = async () => {
               if (providerConfig.product_info_fn && products) {
                    let products_info = [];
                    for (const product in products) {
                         const product_info = await products_api[
                              providerConfig.product_info_fn
                         ](products[product]);
                         if (!product_info) return;
                         products_info.push(product_info);
                    }
                    setProductsInfo(products_info);
               }
          };
          getProductsInfo();
     }, [products, providerConfig]);

     const fontDeclaration = `
          @font-face {
               font-family: "Provider";
               src: url(${
                    BASE_URL +
                    "assets/fonts/" +
                    providerConfigFile?.font_filename
               });
               font-display: fallback;
          }
     `;

     if (
          (isInvoiceLoading ||
               !theme ||
               isInvoiceIdle ||
               isCustomerIdle ||
               isCustomerLoading ||
               (invoice && invoice.subscription && isSubscriptionIdle) ||
               (providerConfigFile?.use_delivery &&
                    (isShippingRatesIdle || isShippingRatesLoading))) &&
          !accountIdError &&
          providerConfigFile
     ) {
          return <Loader isVisible={true} isLoading={true} />;
     }
     if (
          !providerConfigFile ||
          isInvoiceError ||
          accountIdError ||
          isCustomerError ||
          isShippingRatesError ||
          (invoice && !["draft", "open"].includes(invoice.status)) ||
          (invoice && invoice.subscription && isSubscriptionError)
     ) {
          return <InvalidInvoice>Error</InvalidInvoice>;
     }

     return (
          <CheckoutContext.Provider
               value={checkoutContextValue}
               displayName="Checkout Context"
          >
               <style>{fontDeclaration}</style>
               <ThemeProvider theme={theme}>
                    <Elements stripe={StripeObject} options={StripeOptions}>
                         <Checkout accountId={account_id} />
                    </Elements>
               </ThemeProvider>
          </CheckoutContext.Provider>
     );
}
