const themeTypes = {
     card: {
          shadow: ["Card Shadow", "color"],
          bg: ["Card Background", "color"],
          notch: {
               bg: ["Notch color", "color"],
               opacity: ["Notch opacity", "slider", 0, 1, ""], // Slider : [name, type, min, max, unit]
          },
     },
     emailForm: {
          bg: ["Email form bg", "color"],
          br: ["Email form br", "slider", 0, 100, "px"],
          shadow: ["Email form shadow", "color"],
          inputBg: ["Email form input bg", "color"],
          inputFontColor: ["Email form font color", "color"],
     },
     input: {
          br: ["Input border radius", "slider", 0, 100, "px"],
          bg: ["Input background", "color"],
          fontSize: ["Input font size", "text"],
          fontColor: ["Input font color", "color"],
     },
};

export default themeTypes;
