import { CheckoutContext } from "pages/CheckoutWrapper/CheckoutWrapper";
import React, { useContext } from "react";
import { useRef } from "react";
import { useEffect } from "react";
import styled from "styled-components";
import { getCreditCardIcon } from "utils/utils";
import { ReactComponent as ArrowDown } from "../../../assets/svg/arrow_down.svg";

const PaymentMethodPreviewContainer = styled.div`
     padding: 0 7px 32px 7px;
     display: flex;
     justify-content: space-between;
`;

const PaymentMethodPreviewContent = styled.div`
     flex-grow: 1;
     text-align: left;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     gap: 16px;
`;

const PaymentMethodTitle = styled.div``;

const CreditCardPreview = styled.div`
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: left;
`;

const CreditCardIcon = styled.img`
     margin-right: 16px;
     height: 24px;
`;

const PaymentMethodCardNumber = styled.div`
     opacity: 0.5;
`;

const ArrowDownContainer = styled.div`
     display: flex;
     justify-content: center;
     align-items: center;

     svg * {
          fill: ${(props) => props.theme.input.arrowColor};
     }
`;

export default function PaymentMethodPreview() {
     const { paymentMethod, setIsPaymentMethodCardOpen } = useContext(CheckoutContext);
     const CreditCardIconRef = useRef(null);

     useEffect(() => {
          const newIconSrc = getCreditCardIcon(paymentMethod?.card?.brand);
          CreditCardIconRef.current.src = newIconSrc;
     }, [paymentMethod]);

     return (
          <PaymentMethodPreviewContainer>
               <PaymentMethodPreviewContent>
                    <PaymentMethodTitle>Payment</PaymentMethodTitle>
                    <CreditCardPreview onClick={() => setIsPaymentMethodCardOpen(true)}>
                         <CreditCardIcon ref={CreditCardIconRef} src={"/images/cc-icons/default.svg"} />
                         <PaymentMethodCardNumber>
                              {!!paymentMethod ? "**** " + paymentMethod.card.last4 : "Add"}
                         </PaymentMethodCardNumber>
                    </CreditCardPreview>
               </PaymentMethodPreviewContent>
               <ArrowDownContainer onClick={() => setIsPaymentMethodCardOpen(true)}>
                    <ArrowDown />
               </ArrowDownContainer>
          </PaymentMethodPreviewContainer>
     );
}
