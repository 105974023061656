import React, { useEffect } from "react";
import "./style.css";
import { API_URL } from "../../config/config";
import { useSearchParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

export default function RequestAccess() {
     // eslint-disable-next-line no-unused-vars
     const [searchParams, setSearchParams] = useSearchParams();
     const navigate = useNavigate();

     useEffect(() => {
          const getAccess = async () => {
               const requestOptions = {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
               };
               const accessResponse = await fetch(
                    API_URL + "stripe/request-access/" + searchParams.get("code"),
                    requestOptions
               );
               const access = await accessResponse.json();
               console.log(access);
               if (!access.error) {
                    navigate("/", { state: { requestSuccess: true } });
               } else {
                    navigate("/", { state: { requestSuccess: false } });
               }
          };
          getAccess();
          // eslint-disable-next-line react-hooks/exhaustive-deps
     }, []);
     return <div>Loading</div>;
}
