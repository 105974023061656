import { CheckoutContext } from "pages/CheckoutWrapper/CheckoutWrapper";
import React, { useContext } from "react";
import { useState } from "react";
import styled, { keyframes, css } from "styled-components";
import { FormatPrice } from "utils/utils";
import { ReactSVG } from "react-svg";
import { BASE_URL, PROVIDER_SVG_FOLDER } from "config/config";
import { ReactComponent as ArrowDown } from "../../../assets/svg/arrow_down.svg";
import { fetchInvoice, fetchInvoiceProducts, updateItemQuantity } from "api/stripe";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const CartItemContainer = styled.div`
     width: 100%;
     position: relative;
     display: flex;
     gap: 16px;
     flex-direction: row;
     color: ${(props) => props.theme.font_color};
     font-family: "Provider";
`;

const SelectorSlideAnimation = keyframes`
     from {
          opacity: 0;
          bottom: 1em;
     }
     to {
          opacity: 0.5;
          bottom: 0;
     }
`;
const SelectorSlideAnimationSelected = keyframes`
     from {
          opacity: 0;
          bottom: 1em;
     }
     to {
          opacity: 1;
          bottom: 0;
     }
`;

const CartItemImageContainer = styled.div`
     position: relative;
     height: 100px;
     width: 100px;
     border-radius: 10px;
     background: #f5f5f5;
     opacity: ${(props) => (props.isPlaceholder ? ".8" : "1")};
     display: flex;
     justify-content: center;
     align-items: center;
     flex-shrink: 0;

     svg {
          height: 50%;
          width: 50%;

          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
     }
`;

const CartItemImage = styled.img`
     position: absolute;
     height: 100%;
     width: 100%;
     border-radius: 10px;
     background: #f5f5f5;
     display: flex;
     justify-content: center;
     align-items: center;
`;

const CartItemDescription = styled.div`
     display: flex;
     justify-content: space-between;
     gap: 2rem;
`;

const CartItemTitle = styled.div`
     height: 20px;
     white-space: nowrap;
     overflow: hidden;
     text-overflow: ellipsis;
`;

const CartItemPrice = styled.div``;
const CartItemQuantity = styled.div``;
const CartItemSize = styled.div``;
const CartItemColor = styled.div`
     width: 28px;
     height: 28px;
     background-color: ${(props) => props.color};
     border-radius: 100%;
`;

const CartItemInfoContainer = styled.div`
     flex-grow: 1;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     overflow: hidden;
`;

const CartItemParametersContainer = styled.div`
     display: flex;
     justify-content: space-between;
`;

const CartItemParameter = styled.div`
     display: flex;
     flex-direction: row;
     align-items: center;
     justify-content: center;
     gap: 10px;

     svg * {
          fill: ${(props) => props.theme.input.arrowColor};
     }
`;

const CartItemParameterModifiers = styled.div`
     min-height: 20px;
     position: relative;
`;

const CartItemParameterOptionText = styled.div`
     position: relative;
     opacity: 0;
     transition: opacity 0.3s linear;
     animation-delay: ${(props) => props.animationDelay};
     transition-delay: ${(props) => props.animationDelay};
`;

const CartItemParameterOptionColor = styled.div`
     position: relative;
     opacity: 0;
     transition: opacity 0.3s linear;
     animation-delay: ${(props) => props.animationDelay};
     transition-delay: ${(props) => props.animationDelay};
     width: 20px;
     height: 20px;
     border-radius: 100%;
     background-color: ${(props) => props.color};
`;

const CartItemParameterOptionList = styled.div`
     position: absolute;
     top: 0;
     width: 100%;
     display: flex;
     flex-direction: row;
     justify-content: space-between;
     align-items: center;
     transition: opacity 0.1s linear;
     ${(props) =>
          props.active
               ? css`
                      opacity: 1;
                      & ${CartItemParameterOptionText}:not(.selected) {
                           animation: ${SelectorSlideAnimation} 0.1s forwards;
                      }
                      & ${CartItemParameterOptionText}.selected {
                           animation: ${SelectorSlideAnimationSelected} 0.1s forwards;
                      }
                      & ${CartItemParameterOptionColor}:not(.selected) {
                           animation: ${SelectorSlideAnimation} 0.1s forwards;
                      }
                      & ${CartItemParameterOptionColor}.selected {
                           animation: ${SelectorSlideAnimationSelected} 0.1s forwards;
                      }
                 `
               : css`
                      opacity: 0;
                      pointer-events: none;
                 `};
`;

const ParameterArrowContainer = styled.div`
     opacity: ${(props) => (props.active ? 1 : 0.5)};
`;

export default function CartItem({ invoiceItemIndex }) {
     const { currentStatus, invoice, setInvoice, products, providerConfig, productsInfo } = useContext(CheckoutContext);
     const { provider, invoice_id } = useParams();

     const [currentInvoiceItem, setCurrentInvoiceItem] = useState(invoice.lines.data[invoiceItemIndex]);
     const [currentProduct, setCurrentProduct] = useState(products[invoiceItemIndex]);
     const [currentProductInfo, setCurrentProductInfo] = useState(null);

     const sizes = ["XS", "S", "M", "L", "XL", "XXL"];
     const colors = ["#f5f5f5", "rgb(235 252 221)", "rgb(221 241 252)", "rgb(252 221 252)", "rgb(252 221 221)"];

     const [selectedSize, setSelectedSize] = useState("L");
     const [selectedColor, setSelectedColor] = useState(products[invoiceItemIndex]?.metadata?.color);
     const [selectedQuantity, setSelectedQuantity] = useState(currentInvoiceItem.quantity);

     const [isSizeParameterOpen, setIsSizeParameterOpen] = useState(false);
     const [isColorParameterOpen, setIsColorParameterOpen] = useState(false);
     const [isQuantityParameterOpen, setIsQuantityParameterOpen] = useState(false);

     const [canEditColor, setCanEditColor] = useState(products[invoiceItemIndex]?.metadata?.can_edit_color === "true");

     const editable = providerConfig.can_update_cart && invoice.status === "draft";

     useEffect(() => {
          if (!productsInfo || !currentProduct) return;
          const productInfo = productsInfo.find((info) => {
               return info.stripe_id === currentProduct.id;
          });
          if (!productInfo) return;
          console.log(productInfo);
          setCurrentProductInfo(productInfo);
     }, [productsInfo, currentProduct]);

     const onQuantityMenuClick = () => {
          if (editable) {
               setIsSizeParameterOpen(false);
               setIsColorParameterOpen(false);
               setIsQuantityParameterOpen(!isQuantityParameterOpen);
          }
     };
     const onSizeMenuClick = () => {
          if (editable) {
               setIsSizeParameterOpen(!isSizeParameterOpen);
               setIsColorParameterOpen(false);
               setIsQuantityParameterOpen(false);
          }
     };
     const onColorMenuClick = () => {
          if (editable) {
               setIsSizeParameterOpen(false);
               setIsColorParameterOpen(!isColorParameterOpen);
               setIsQuantityParameterOpen(false);
          }
     };

     const updateQuantity = async (quantity) => {
          if (providerConfig.can_update_cart && invoice.status === "draft") {
               const invoiceItem = await updateItemQuantity(currentInvoiceItem.id, quantity, provider);
               const updatedInvoice = await fetchInvoice(invoice_id, provider);
               setInvoice(updatedInvoice);
               setCurrentInvoiceItem(updatedInvoice?.lines?.data[invoiceItemIndex]);
          }
     };

     return (
          <CartItemContainer>
               <CartItemImageContainer
                    isPlaceholder={
                         currentProduct.images.length === 0 || (providerConfig.product_info_fn && !currentProductInfo)
                    }
               >
                    {!!providerConfig.product_info_fn && currentProductInfo ? (
                         <CartItemImage src={currentProductInfo.main_image} />
                    ) : !providerConfig.product_info_fn ? (
                         <CartItemImage src={currentProduct.images[0]} />
                    ) : (
                         <ReactSVG
                              src={BASE_URL + PROVIDER_SVG_FOLDER + provider + "/" + providerConfig.logo.main.filename}
                         />
                    )}

                    {/* {!!currentProductInfo && <CartItemImage src={currentProductInfo.main_image} />}
                    {currentProduct.images.length > 0 && !providerConfig.product_info_fn && !currentProductInfo && (
                         <CartItemImage src={currentProduct.images[0]} />
                    )}
                    {currentProduct.images.length === 0 && !currentProductInfo && (
                         <ReactSVG
                              src={BASE_URL + PROVIDER_SVG_FOLDER + provider + "/" + providerConfig.logo.main.filename}
                         />
                    )} */}
               </CartItemImageContainer>
               <CartItemInfoContainer>
                    <CartItemDescription>
                         <CartItemTitle>{currentInvoiceItem.description}</CartItemTitle>
                         <CartItemPrice>
                              {FormatPrice(currentInvoiceItem.amount, currentInvoiceItem.currency)}
                         </CartItemPrice>
                    </CartItemDescription>
                    <CartItemParametersContainer>
                         <CartItemParameter onClick={onQuantityMenuClick}>
                              <CartItemQuantity>{selectedQuantity}</CartItemQuantity>
                              <ParameterArrowContainer active={editable}>
                                   <ArrowDown />
                              </ParameterArrowContainer>
                         </CartItemParameter>
                         {/* <CartItemParameter onClick={onSizeMenuClick}>
                              <CartItemSize>{selectedSize}</CartItemSize>
                              <ParameterArrowContainer active={editable}>
                                   <ArrowDown />
                              </ParameterArrowContainer>
                         </CartItemParameter> */}
                         {canEditColor && (
                              <CartItemParameter onClick={onColorMenuClick}>
                                   <CartItemColor color={selectedColor} />
                                   <ParameterArrowContainer active={editable}>
                                        <ArrowDown />
                                   </ParameterArrowContainer>
                              </CartItemParameter>
                         )}
                    </CartItemParametersContainer>
                    <CartItemParameterModifiers>
                         <CartItemParameterOptionList key={"quantity"} active={isQuantityParameterOpen}>
                              {(() => {
                                   let quantitySelectors = [];
                                   for (let i = 1; i <= 5; i++) {
                                        quantitySelectors.push(
                                             <CartItemParameterOptionText
                                                  className={selectedQuantity === i ? "selected" : ""}
                                                  animationDelay={i * 80 + "ms !important"}
                                                  key={i}
                                                  onClick={() => {
                                                       setSelectedQuantity(i);
                                                       updateQuantity(i);
                                                       setIsQuantityParameterOpen(false);
                                                  }}
                                             >
                                                  {i}
                                             </CartItemParameterOptionText>
                                        );
                                   }
                                   return quantitySelectors;
                              })()}
                         </CartItemParameterOptionList>

                         <CartItemParameterOptionList key={"sizes"} active={isSizeParameterOpen}>
                              {sizes.map((size, index) => {
                                   return (
                                        <CartItemParameterOptionText
                                             className={size === selectedSize ? "selected" : ""}
                                             animationDelay={index * 80 + "ms !important"}
                                             onClick={() => {
                                                  setSelectedSize(size);
                                                  setIsSizeParameterOpen(false);
                                             }}
                                             key={size}
                                        >
                                             {size}
                                        </CartItemParameterOptionText>
                                   );
                              })}
                         </CartItemParameterOptionList>

                         {canEditColor && (
                              <CartItemParameterOptionList key={"colors"} active={isColorParameterOpen}>
                                   {products[invoiceItemIndex].metadata.available_colors
                                        .split(";")
                                        .map((color, index) => {
                                             return (
                                                  <CartItemParameterOptionColor
                                                       className={color == selectedColor ? "selected" : ""}
                                                       animationDelay={index * 80 + "ms !important"}
                                                       color={color}
                                                       onClick={() => {
                                                            setSelectedColor(color);
                                                            setIsColorParameterOpen(false);
                                                       }}
                                                       key={color}
                                                  ></CartItemParameterOptionColor>
                                             );
                                        })}
                              </CartItemParameterOptionList>
                         )}
                    </CartItemParameterModifiers>
               </CartItemInfoContainer>
          </CartItemContainer>
     );
}
