exports.theme = {
     bg: "#FFF",
     background_image: "",
     bg_processing: "#333333",
     loader_color: "#FFF",
     font_color: "#000",
     progress_bar_color: "#000",
     font_size: "16px",
     global_style: `
          * {letter-spacing: -1px;}
     `,
     card: {
          shadow: "rgba(0, 0, 0, 0.2)",
          br: "32px 32px 0 0",
          bg: "#FFF",
          notch: {
               bg: "#000",
               opacity: ".2",
          },
     },
     emailForm: {
          bg: "#FFF",
          br: "32px 32px 0 0",
          shadow: "rgba(0, 0, 0, 0.2)",
          inputBg: "#0A71E2",
          inputFontColor: "#FFF",
          logoHeight: "100%",
     },
     input: {
          br: "15px",
          bg: "#0A71E2",
          fontSize: "18px",
          fontColor: "#FFF",
          arrowColor: "#000",
     },
     logo: {
          height: {
               email: "110px",
               cart: "40px",
               subscription: "120px",
               processing: "60px",
          },
          styles: {
               processing: `
               path {fill: #FFF;}
          `,
          },
     },
};
