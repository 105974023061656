import React from "react";
import styled, { css } from "styled-components";
import CardNotch from "components/CardNotch/CardNotch";
import EnterButton from "components/EnterButton/EnterButton";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { CheckoutContext } from "pages/CheckoutWrapper/CheckoutWrapper";
import { useState } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

var cn = require("classnames");

const PaymentMethodCardContentContainer = styled.div`
     transition: top 1.2s cubic-bezier(0.76, 0, 0.24, 1);
     position: absolute;
     top: 32px;
     height: calc(100% - 32px);
     width: 100%;

     background: ${(props) => props.theme.card.bg};
     border-radius: ${(props) => props.theme.card.br};
`;
const PaymentMethodCardContent = styled.div`
     height: 100%;
`;
const PaymentMethodCardContainer = styled.div`
     position: absolute;
     top: 0;
     z-index: 500;
     height: 100%;
     width: 100%;
     font-family: "Provider";
     overflow: hidden;

     & ${PaymentMethodCardContent} {
          transition: opacity 0.3s cubic-bezier(0.76, 0, 0.24, 1) 1.1s;
          opacity: 1;
     }

     ${(props) =>
          props.active
               ? ""
               : css`
                      ${closedStyles}
                 `}
`;

const closedStyles = `
     > div {
          top: 100%;
     }
     pointer-events: none;
     & ${PaymentMethodCardContent} {
          transition: opacity 0.1s cubic-bezier(0.76, 0, 0.24, 1) 1.1s;
          opacity: 0;
     }

     
`;

const PaymentMethodInputContainer = styled.div`
     padding: 48px 40px 0 40px;
`;

const SaveCardButtonContainer = styled.div`
     position: absolute;
     top: calc(50% + 64px);
     width: 100%;
`;

const SavedPaymentMethodsContainer = styled.div`
     padding: 0 40px 0 40px;
     margin-top: 50px;
     display: flex;
     flex-direction: column;
     gap: 20px;
     max-height: calc(50% - 210px);
     overflow: scroll;
`;

const SavedPaymentMethod = styled.span`
     display: flex;
     justify-content: left;
     align-items: center;
     transform-origin: center center;
     transition: transform 0.05s linear;
     width: fit-content;
     white-space: nowrap;
     color: ${(props) => props.theme.font_color};
     &:active {
          transform: scale(0.9);
          span {
               opacity: 1;
          }
     }
     span {
          opacity: 0.5;
     }
`;

const CreditCardIcon = styled.img`
     margin-right: 16px;
     height: 24px;
`;

export default function PaymentMethodCard() {
     const theme = useContext(ThemeContext);
     const stripe = useStripe();
     const elements = useElements();
     const { isPaymentMethodCardOpen, setIsPaymentMethodCardOpen, setPaymentMethod, paymentMethods } =
          useContext(CheckoutContext);
     const [isCardValid, setIsCardValid] = useState(false);
     const [isEnterButtonLoading, setIsEnterButtonLoading] = useState(false);

     const cardInputStyle = {
          fontWeight: "500",
          fontSize: "16px",
          fontSmoothing: "antialiased",
          fontFamily: "ProviderFont",
          color: theme.font_color,
     };

     const checkIfCardValid = (event) => {
          if (event.error || !event.complete) {
               setIsCardValid(false);
          } else {
               setIsCardValid(true);
          }
     };

     const createPaymentMethod = async () => {
          const paymentMethodResponse = await stripe.createPaymentMethod({
               type: "card",
               card: elements.getElement(CardElement),
          });
          if (!!paymentMethodResponse.paymentMethod) {
               setPaymentMethod(paymentMethodResponse.paymentMethod);
               return true;
          } else {
               return false;
          }
     };

     const handleEnterButtonClick = async () => {
          setIsEnterButtonLoading(true);
          const isPaymentMethodCreated = await createPaymentMethod();
          setIsEnterButtonLoading(false);
          if (isPaymentMethodCreated) {
               setIsPaymentMethodCardOpen(false);
          } else {
               toast.error("An error occured while validating your payment method", {
                    position: "top-center",
                    autoClose: 3000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    draggable: false,
                    toastId: "payment-method-toast",
               });
          }
     };

     const handleSavePaymentMethodClick = (payment_method) => {
          setPaymentMethod(payment_method);
          setIsPaymentMethodCardOpen(false);
     };

     return (
          <PaymentMethodCardContainer active={isPaymentMethodCardOpen}>
               <PaymentMethodCardContentContainer>
                    <PaymentMethodCardContent>
                         <CardNotch onClick={() => setIsPaymentMethodCardOpen(false)} />
                         <PaymentMethodInputContainer>
                              <CardElement
                                   onChange={checkIfCardValid}
                                   options={{
                                        style: {
                                             base: cardInputStyle,
                                        },
                                   }}
                              ></CardElement>
                         </PaymentMethodInputContainer>
                         <SavedPaymentMethodsContainer>
                              {paymentMethods.map((payment_method) => {
                                   if (!payment_method?.card?.last4) return;
                                   return (
                                        <SavedPaymentMethod
                                             onClick={() => handleSavePaymentMethodClick(payment_method)}
                                             key={payment_method.id}
                                        >
                                             <CreditCardIcon src={"/images/cc-icons/mastercard.svg"} />
                                             <span>**** {payment_method.card.last4}</span>
                                        </SavedPaymentMethod>
                                   );
                              })}
                         </SavedPaymentMethodsContainer>
                         <SaveCardButtonContainer>
                              <EnterButton
                                   enabled={isCardValid}
                                   onClick={handleEnterButtonClick}
                                   isLoading={isEnterButtonLoading}
                              />
                         </SaveCardButtonContainer>
                    </PaymentMethodCardContent>
               </PaymentMethodCardContentContainer>
          </PaymentMethodCardContainer>
     );
}
