import { CheckoutContext } from "pages/CheckoutWrapper/CheckoutWrapper";
import React from "react";
import { useRef } from "react";
import { useEffect } from "react";
import { useContext } from "react";
import styled from "styled-components";

const ProgressBarContainer = styled.div`
     position: absolute;
     z-index: 400;
     top: 0;
     width: 100%;
     border: none;
     margin: 0;
     opacity: ${(props) => (props.enabled ? 1 : 0)};
     transition: opacity 1s cubic-bezier(0.76, 0, 0.24, 1) 0.8s;
     @media (min-width: 768px) {
          display: none;
     }
`;

const ProgressBarContent = styled.div`
     height: 10px;
     transition: width 1s cubic-bezier(0.76, 0, 0.24, 1);
     border-radius: 0 10px 10px 0;
     background-color: ${(props) => props.theme.progress_bar_color};
     border: none;
     margin: 0;
`;

export default function ProgressBar() {
     const {
          billingAddress,
          paymentMethod,
          isEmailFormOpen,
          currentStatus,
          providerConfig,
          shippingRate,
          lockedShippingRate,
     } = useContext(CheckoutContext);
     const progressBarDiv = useRef(null);

     useEffect(() => {
          let isBillingComplete = providerConfig.use_delivery
               ? (!!shippingRate || !!lockedShippingRate) && !!billingAddress
               : !!billingAddress;
          let countRules = [!isEmailFormOpen, paymentMethod, isBillingComplete, currentStatus === "processing"];
          let progressCount = countRules.filter(Boolean).length;

          progressBarDiv.current.style.width = Math.round((progressCount / countRules.length) * 100) + "%";
     }, [billingAddress, paymentMethod, isEmailFormOpen, currentStatus, shippingRate, lockedShippingRate]);

     return (
          <ProgressBarContainer enabled={currentStatus !== "processing"}>
               <ProgressBarContent ref={progressBarDiv}></ProgressBarContent>
          </ProgressBarContainer>
     );
}
