exports.theme = {
     bg: "#FFF",
     background_image: "",
     bg_processing: "#FFF",
     loader_color: "#000",
     font_color: "#000",
     progress_bar_color: "#000",
     font_size: "16px",
     card: {
          shadow: "rgba(0, 0, 0, 0.2)",
          br: "32px 32px 0 0",
          bg: "#FFF",
          notch: {
               bg: "#000",
               opacity: ".2",
          },
     },
     emailForm: {
          bg: "#FFF",
          br: "32px 32px 0 0",
          shadow: "rgba(0, 0, 0, 0.2)",
          inputBg: "#000",
          inputFontColor: "#FFF",
          logoHeight: "80%",
     },
     input: {
          br: "48px",
          bg: "#000",
          fontSize: "18px",
          fontColor: "#FFF",
          arrowColor: "#000",
     },
     logo: {
          height: {
               email: "25px",
               cart: "20px",
               subscription: "25px",
               processing: "20px",
          },
          styles: {
               processing: `
                    margin-top: -20px;
               `,
          },
     },
};
