import { CheckoutContext } from "pages/CheckoutWrapper/CheckoutWrapper";
import React, { useContext } from "react";
import styled, { keyframes } from "styled-components";
import CardNotch from "../CardNotch/CardNotch";
import EmailInput from "./EmailInput/EmailInput";

var cn = require("classnames");

const EmailFormContainer = styled.div`
     position: absolute;
     top: 0;
     z-index: 400;
     height: 100%;
     width: 100%;
     transition: opacity 2s cubic-bezier(0.76, 0, 0.24, 1);
     @media (min-width: 768px) {
          display: none;
     }

     &.closed {
          > div {
               transition: top 1.2s cubic-bezier(0.76, 0, 0.24, 1);
               top: 100%;
          }
          opacity: 0;
          pointer-events: none;
     }
`;

const EmailFormContent = styled.div`
     transition: top 1s cubic-bezier(0.76, 0, 0.24, 1) 1s;
     position: absolute;
     top: 50%;
     height: 50%;
     width: 100%;

     background: ${(props) => props.theme.emailForm.bg};
     border-radius: ${(props) => props.theme.emailForm.br};
`;

export default function EmailForm() {
     const { currentStatus, setCurrentStatus, setIsEmailFormOpen, displayType } = useContext(CheckoutContext);

     const handleEmailBlur = (event) => {
          const isEmailValid = /\S+@\S+\.\S+/.test(event.target.value);
          if (isEmailValid) {
               setCurrentStatus(displayType);
               setIsEmailFormOpen(false);
          }
     };

     return (
          <EmailFormContainer
               className={cn({
                    closed: currentStatus !== "email",
               })}
          >
               <EmailFormContent>
                    <CardNotch />
                    <EmailInput onBlur={(e) => handleEmailBlur(e)} />
               </EmailFormContent>
          </EmailFormContainer>
     );
}
