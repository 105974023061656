import { CheckoutContext } from "pages/CheckoutWrapper/CheckoutWrapper";
import React, { useContext } from "react";
import styled from "styled-components";
import BillingPreview from "./BillingPreview/BillingPreview";
import PayButton from "./PayButton/PayButton";
import PaymentMethodPreview from "./PaymentMethodPreview/PaymentMethodPreview";
var cn = require("classnames");

const FooterContainer = styled.div`
     position: absolute;
     bottom: 0;
     width: 100%;
     padding: 0 18px 18px 18px;
     font-family: "Provider";
     color: ${(props) => props.theme.font_color};
     font-size: ${(props) => props.theme.font_size};
     transition: opacity 1s cubic-bezier(0.65, 0, 0.35, 1)
          ${(props) => (["cart", "subscription"].includes(props.status) ? "1s" : "0s")};
     opacity: ${(props) => (["email", "processing"].includes(props.status) ? "0" : "")};
`;
export default function Footer({ onPayButtonClick = undefined }) {
     const { currentStatus, setIsPaymentMethodCardOpen, setIsBillingCardOpen } = useContext(CheckoutContext);

     return (
          <FooterContainer status={currentStatus}>
               <BillingPreview onClick={() => setIsBillingCardOpen(true)} />
               <PaymentMethodPreview onClick={() => setIsPaymentMethodCardOpen(true)} />
               <PayButton onClick={onPayButtonClick} />
          </FooterContainer>
     );
}
