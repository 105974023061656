import React from "react";
import styled from "styled-components";
import { CheckoutContext } from "../../pages/CheckoutWrapper/CheckoutWrapper";
import { ReactSVG } from "react-svg";
import { useContext } from "react";
import { BASE_URL, PROVIDER_SVG_FOLDER } from "config/config";
import { useParams } from "react-router-dom";

var cn = require("classnames");

const ProviderLogoContainer = styled.div`
     position: absolute;
     z-index: 400;
     left: 50%;
     transform: translate(-50%, -50%);
     top: 0;
     -webkit-transform-style: preserve-3d;
     transform-style: preserve-3d;
     -webkit-backface-visibility: hidden;
     backface-visibility: hidden;

     @media (min-width: 768px) {
          ${(props) => props.theme.logo.styles.processing};
          top: 50% !important;
     }

     display: flex;
     justify-content: center;
     align-items: center;
     width: fit-content;
     transition: all 1s cubic-bezier(0.76, 0, 0.24, 1) 1s;
     transform-origin: center center;
     svg {
          transition: all 1s cubic-bezier(0.76, 0, 0.24, 1) 1s;
     }

     &.zap-logo-status-email {
          top: 25%;
          svg {
               height: ${(props) => props.theme.logo.height.email};
          }
     }

     &.zap-logo-status-cart {
          transition: all 1s cubic-bezier(0.76, 0, 0.24, 1);
          top: calc(${(props) => props.theme.logo.height.cart} / 2 + 32px);
          svg {
               height: ${(props) => props.theme.logo.height.cart};
               transition: all 1s cubic-bezier(0.76, 0, 0.24, 1);
          }

          svg * {
               transition: all 1s cubic-bezier(0.76, 0, 0.24, 1);
          }

          ${(props) => props.theme.logo.styles.cart};
     }

     &.zap-logo-status-subscription {
          transition: all 1s cubic-bezier(0.76, 0, 0.24, 1);
          top: calc((100% - 290px) / 2);
          svg {
               transition: all 1s cubic-bezier(0.76, 0, 0.24, 1);
               height: ${(props) => props.theme.logo.height.subscription};
          }

          svg * {
               transition: all 1s cubic-bezier(0.76, 0, 0.24, 1);
          }
     }

     &.zap-logo-status-processing {
          transition: all 2s cubic-bezier(0.76, 0, 0.24, 1) 1s;
          top: calc(50% - (${(props) => props.theme.logo.height.processing}) - 10px);

          svg {
               transition: all 2s cubic-bezier(0.76, 0, 0.24, 1) 1s;
               height: ${(props) => props.theme.logo.height.processing};
          }

          svg * {
               transition: all 2s cubic-bezier(0.76, 0, 0.24, 1) 1s;
          }
          ${(props) => props.theme.logo.styles.processing};
     }
`;

export default function ProviderLogo() {
     const { providerConfig, currentStatus } = useContext(CheckoutContext);
     const { provider } = useParams();
     return (
          <>
               <ProviderLogoContainer
                    className={cn({
                         "zap-logo-status-email": currentStatus === "email",
                         "zap-logo-status-cart": currentStatus === "cart",
                         "zap-logo-status-subscription": currentStatus === "subscription",
                         "zap-logo-status-processing": currentStatus === "processing",
                    })}
               >
                    <ReactSVG
                         src={BASE_URL + PROVIDER_SVG_FOLDER + provider + "/" + providerConfig.logo.main.filename}
                    />
               </ProviderLogoContainer>
          </>
     );
}
