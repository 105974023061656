import ProviderLogo from "components/ProviderLogo/ProviderLogo";
import React from "react";
import styled from "styled-components";
import { ReactComponent as ErrorIcon } from "../../assets/svg/error.svg";

const InvalidInvoiceContainer = styled.div`
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
     background-color: ${(props) => props.theme.bg};
`;

const ErrorContainer = styled.div`
     position: absolute;
     top: 50%;
     left: 50%;
     transform: translate(-50%, -50%);

     display: flex;
     flex-direction: column;
     align-items: center;
     justify-content: center;

     p {
          font-size: 24px;
     }
`;

const ErrorIconContainer = styled.div`
     width: 15vw;
     height: 15vw;
     margin-bottom: 30px;
     svg {
          height: 100%;
          width: 100%;
     }
`;

export default function InvalidInvoice() {
     return (
          <InvalidInvoiceContainer>
               <ErrorContainer>
                    <ErrorIconContainer>
                         <ErrorIcon />
                    </ErrorIconContainer>
                    <p>Invalid request</p>
               </ErrorContainer>
          </InvalidInvoiceContainer>
     );
}
