exports.theme = {
     bg: "#FFF",
     background_image: "",
     bg_processing: "#0046bf",
     loader_color: "#FFF",
     font_color: "#000",
     progress_bar_color: "#0046bf",
     font_size: "16px",
     card: {
          shadow: "rgba(0, 0, 0, 0.2)",
          br: "32px 32px 0 0",
          bg: "#FFF",
          notch: {
               bg: "#000",
               opacity: ".2",
          },
     },
     emailForm: {
          bg: "#FFF",
          br: "32px 32px 0 0",
          shadow: "rgba(0, 0, 0, 0.2)",
          inputBg: "#ffed31",
          inputFontColor: "#000",
          logoHeight: "80%",
     },
     input: {
          br: "999px",
          bg: "#ffed31",
          fontSize: "18px",
          fontColor: "#000",
          arrowColor: "#000",
     },
     logo: {
          height: {
               email: "90px",
               cart: "50px",
               subscription: "80px",
               processing: "50px",
          },
          styles: {
               processing: `
               margin-top: -10px;
               path:nth-of-type(2), path:nth-of-type(3), path:nth-of-type(4) {
                    fill: #FFF;
               }
               `,
          },
     },
};
