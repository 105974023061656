exports.theme = {
     bg: "#FFF",
     background_image: "",
     bg_processing: "#232f3e",
     loader_color: "#FFF",
     font_color: "#000",
     progress_bar_color: "#ff9900",
     font_size: "16px",
     card: {
          shadow: "rgba(0, 0, 0, .3)",
          br: "32px 32px 0 0",
          bg: "#FFF",
          notch: {
               bg: "#000",
               opacity: ".2",
          },
     },
     emailForm: {
          bg: "#FFF",
          br: "32px 32px 0 0",
          shadow: "rgba(0, 0, 0, .2)",
          inputBg: "#ff9900",
          inputFontColor: "#232f3e",
          logoHeight: "100%",
     },
     input: {
          br: "10px",
          bg: "#ff9900",
          fontSize: "19px",
          fontColor: "#232f3e",
          arrowColor: "#000",
     },
     logo: {
          height: {
               email: "80px",
               cart: "50px",
               subscription: "120px",
               processing: "50px",
          },
          styles: {
               processing: `
               path:nth-child(2) {fill: #FFF;}
          `,
          },
     },
};
